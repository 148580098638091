import { UserAddOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import './profile.css';
import { selectUser } from '../../auth/store/authSlice';
import { updateUserThunk } from '../thunks/usersThunk';
import showMessage from 'src/app/core/utils/show-message';
import { selectLoading } from '../store/usersSlice';
import { Roles } from 'src/app/routes/guards/RoleProtectedRoute';

export default function Profile() {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);

  const updateProfile = (values: any) => {
    const { email, ...v } = values;
    dispatch(updateUserThunk({ id: String(user?.id), data: { ...v } }))
      .unwrap()
      .then(() => {
        showMessage('success', 'Profile updated  successfully');
      })
      .catch(() => showMessage('error', 'Error updating profile'));
  };

  const roleOptions = Object.values(Roles).map((role) => ({
    value: role,
    label: role.charAt(0).toUpperCase() + role.slice(1),
  }));

  return (
    <div className='container'>
      {user && (
        <Form layout='vertical' onFinish={updateProfile}>
          <Card title='Update Profile' className='form'>
            <Row gutter={[5, 0]}>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  initialValue={user.firstName}
                  label='First Name'
                  name='firstName'
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input placeholder='First Name' />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  initialValue={user.lastName}
                  label='Last Name'
                  name='lastName'
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input placeholder='Last Name' />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item initialValue={user.role} label='Select a role' name='role'>
                  <Select
                    placeholder='Select a role'
                    optionFilterProp='label'
                    style={{ width: 175 }}
                    options={roleOptions}
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  initialValue={user.phone}
                  label='Phone number'
                  name='phone'
                  rules={[{ required: true, message: 'Phone number is required' }]}
                >
                  <Input type='number' placeholder='Phone number' className='input' />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item initialValue={user.country} label='Country' name='country'>
                  <Input placeholder='Country' />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                {' '}
                <Form.Item initialValue={user.state} label='State' name='state'>
                  <Input placeholder='State' className='input' />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                {' '}
                <Form.Item label='City' name='city'>
                  <Input placeholder='city' />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item initialValue={user.zipcode} label='Zipcode' name='zipcode'>
                  <Input placeholder='Zipcode' className='input' />
                </Form.Item>
              </Col>

              <Col lg={{ span: 24 }} xs={{ span: 24 }}>
                <Form.Item
                  initialValue={user.email}
                  label='Email'
                  name={'email'}
                  rules={[{ required: true, type: 'email' }]}
                >
                  <Input disabled type='email' placeholder='Enter email' />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ justifyContent: 'space-evenly' }}>
              <Col span={10} offset={14}>
                <Button
                  icon={<UserAddOutlined />}
                  className='btn btn-primary'
                  type='primary'
                  htmlType='submit'
                  loading={loading === true}
                  style={{ float: 'right' }}
                >
                  Update Profile
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      )}
    </div>
  );
}
