import { createSlice } from '@reduxjs/toolkit';
import { User } from 'rentfree-api';
import { createUserThunk, getUsersThunk } from '../thunks/usersThunk';
import { RootState } from 'src/app/core/store/store';
import { Paginated } from '@feathersjs/feathers';

interface UsersState {
  users: Paginated<User>;
  loading: boolean | null;
  error: any;
}

const initialState: UsersState = {
  users: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
  loading: null,
  error: null,
};

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createUserThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getUsersThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
    });
    builder.addCase(getUsersThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { setUsers } = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users.users;
export const selectLoading = (state: RootState) => state.users.loading;
export const selectError = (state: RootState) => state.users.error;

export default usersSlice.reducer;
