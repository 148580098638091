import _ from 'lodash';
import { useEffect, useRef } from 'react';

/**
 * Custom hook that runs the effect only when the dependencies have deeply changed.
 */

function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
  const previousDependenciesRef = useRef<any[]>([]);

  if (!_.isEqual(previousDependenciesRef.current, dependencies)) {
    previousDependenciesRef.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [previousDependenciesRef.current]);
}

export default useDeepCompareEffect;
