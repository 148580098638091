import { Col, Input, Form, AutoComplete } from 'antd';
import { Property } from 'rentfree-api';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectLgaSearchResults } from 'src/app/features/property/store/propertySlice';
import { thunkLgaSearch } from 'src/app/features/property/store/thunk';

interface Props {
  property?: Property;
}

export default function LocationDetails(props: Props) {
  const { property } = props;
  const searchResults = useAppSelector(selectLgaSearchResults);
  const dispatch = useAppDispatch();

  const stateLgaSearch = (e: string) => {
    dispatch(thunkLgaSearch({ q: e }));
  };

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='address'
          label='Address'
          rules={[{ required: true, message: 'Please input your address' }]}
          initialValue={property ? property.address : ''}
        >
          <Input type='text' placeholder='Enter address' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          initialValue={property ? property.country : 'Nigeria'}
          name='country'
          label='Country'
          rules={[{ required: true, message: 'Please input your Country' }]}
        >
          <Input disabled type='text' placeholder='Enter country' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='state'
          label='State'
          rules={[{ required: true, message: 'Please input your State' }]}
          initialValue={property ? property.state : ''}
        >
          <Input type='text' placeholder='Enter state' />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item name='lga' label='City - LGA'>
          <AutoComplete
            options={searchResults}
            placeholder='Please select your lga'
            onSearch={stateLgaSearch}
          />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='zipcode'
          label='zipcode'
          rules={[{ required: true, message: 'Please input your zipcode' }]}
          initialValue={property ? property.zipcode : ''}
        >
          <Input type='number' placeholder='Enter zipcode' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='latitude'
          label='Latitude'
          rules={[{ required: true, message: 'Please input your Latitude' }]}
          initialValue={property ? property.latitude : ''}
        >
          <Input type='text' placeholder='Enter latitude' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='longitude'
          label='Longitude'
          rules={[{ required: true, message: 'Please input your Longitude' }]}
          initialValue={property ? property.longitude : ''}
        >
          <Input type='text' placeholder='Enter longitude' />
        </Form.Item>
      </Col>
    </>
  );
}
