import { ReactComponent as Like } from 'src/app/asset/icons/like.svg';
import { ReactComponent as Share } from 'src/app/asset/icons/share.svg';
import { ReactComponent as Eye } from 'src/app/asset/icons/eye.svg';
import { ReactComponent as Bedroom } from 'src/app/asset/icons/bedroom.svg';
import { ReactComponent as Location } from 'src/app/asset/icons/Location.svg';
import { ReactComponent as Bathroom } from 'src/app/asset/icons/bathroom.svg';
import './PropertyCard.css';
import { useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import { APP_URL } from 'src/app/lib/variables';
import { formatMoney } from 'src/app/core/utils/helpers';

interface PropertyCardProps
  extends React.BaseHTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  title: string;
  img: string;
  price: number;
  location: string;
  description: string;
  url: string;
  bedrooms: number;
  bathrooms: number;
  createdAt?: string;
}

export default function PropertyCard(props: PropertyCardProps) {
  const {
    title,
    img,
    price,
    location,
    description,
    url,
    bedrooms,
    bathrooms,
    createdAt,
  } = props;

  const navigate = useNavigate();

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'check out this property',
          text: 'I found something amazing!',
          url: `${APP_URL}${url}`,
        });
      } catch (error) {
        message.error('Failed to share');
      }
    }
  };

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShare();
  };

  return (
    <div className='ApartmentCardContainer' onClick={() => navigate(url)}>
      <div className='cardimg'>
        <img src={img} alt='apartment' />
      </div>
      <div className='carddetails'>
        <p className='time text-small text-subdued'>{createdAt}</p>
        <p className='text-medium text-semibold'>{title}</p>
        <p className='text-blue text-semibold text-blue' style={{ marginTop: '9px' }}>
          {formatMoney(String(price))}
        </p>
        <div className='address'>
          <Location className='icon' />
          <p className='text-subdued text-small text-semibold'>{location}</p>
        </div>
        <p className='text-subdued text-small' style={{ marginTop: '9px' }}>
          {description}
        </p>
        <div className='address'>
          <p className='text-smallest text-subdued'>
            Current pools (<span className='readmore'>20</span>)
          </p>
          <Eye className='icon ' />
        </div>

        <div className='cardfooter'>
          <div className='properties'>
            <div className='sub-button'>
              <Bedroom />
              <p className='text-small text-subdued'>{bedrooms} bedrooms</p>
            </div>
            <div className='sub-button'>
              <Bathroom />
              <p className='text-small text-subdued'>{bathrooms} bathrooms</p>
            </div>
          </div>
          <div className='cardaction'>
            <Button type='link' className='sub-button' onClick={handleShareClick}>
              <Share />
              <p className='text-small text-subdued'>Share</p>
            </Button>
            <Button type='link' className='sub-button'>
              <Like />
              <p className='text-small text-subdued'>Like</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
