import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

export default function AdminContent() {
  return (
    <Content
      style={{
        margin: '30px 16px 0',
        overflow: 'auto',
        minHeight: '90vh',
      }}
      className='admin-dashboard-content layout-content'
    >
      <Outlet />
    </Content>
  );
}
