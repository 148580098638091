import * as React from 'react';
import { Navigate, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingScreen from 'src/app/core/components/LoadingScreen';
import showMessage from 'src/app/core/utils/show-message';
import useAuthUser from 'src/app/hooks/useAuthUser';
import { TOKEN_STORAGE_KEY } from 'src/app/lib/variables';

export default function AuthRoutesGuard() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthUser();
  const [seachParams] = useSearchParams();
  const error = seachParams.get('error');
  const accessToken = seachParams.get('access_token');

  React.useEffect(() => {
    if (error) {
      const message =
        error === 'authentication_failed' ? 'Authentication failed' : 'An error occurred';
      showMessage('error', message);
    }

    if (accessToken) {
      localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
      navigate('/', { replace: true });
    }
  }, [error, accessToken, navigate]);

  React.useEffect(() => {
    if (isAuthenticated === true) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  // if (authLoading) return <LoadingScreen />;
  if (isAuthenticated === true) return <Navigate to='/' />;

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      {isAuthenticated === null || (!isAuthenticated && <Outlet />)}
    </React.Suspense>
  );
}
