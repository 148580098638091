import { Badge, Button, Dropdown, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  LogoutOutlined,
  EditOutlined,
  SettingOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectUser } from '../../auth/store/authSlice';
import { thunkLogout } from '../../auth/store/thunks';

export default function AdminHeaderMenu() {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  function handleLogout() {
    dispatch(thunkLogout());
  }

  const profileMenuitems: MenuProps['items'] = [
    {
      label: <Link to={'/dashboard/profile'}>Edit Profile</Link>,
      key: '0',
      icon: <EditOutlined />,
    },
    {
      label: <Link to={'#'}>Account Settings</Link>,
      key: '1',
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: '3',
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(),
    },
  ];
  return (
    <>
      <div className='header-control'>
        <Badge size='default' count={5}>
          <Button icon={<BellOutlined />} shape='circle' type='link' />
        </Badge>
        <Dropdown trigger={['click']} menu={{ items: profileMenuitems }}>
          <Button
            data-testId='admin-dropdown-button'
            icon={<UserOutlined />}
            shape='circle'
            type='link'
          >
            <span className='user-name'>{user?.firstName}</span>
          </Button>
        </Dropdown>
      </div>
    </>
  );
}
