import * as React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Typography,
  Table,
  Input,
  Select,
  Tooltip,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import {
  thunkDeleteProperty,
  thunkListProperties,
  thunkPatchProperty,
} from './store/thunks';
import {
  formatMoney,
  getDetailedRelativeTime,
  truncateString,
} from 'src/app/core/utils/helpers';
import { Property, PropertyQuery } from 'rentfree-api';
import useAuthUser from 'src/app/hooks/useAuthUser';
import showMessage from 'src/app/core/utils/show-message';
import { selectProperties } from './store/adminPropertySlice';
import useQueryParams from 'src/app/hooks/useQueryParams';
import useDeepCompareEffect from 'src/app/hooks/useDeepCompareEffect';
import useUpdatedEffect from 'src/app/hooks/useUpdatedEffect';
import useDebounce from 'src/app/hooks/useDebounce';
import dayjs from 'dayjs';
import GenericPagination from 'src/app/core/components/GenericPagination';

export default function ViewProperties() {
  const { queryParams, updateQueryParams } = useQueryParams<PropertyQuery>();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { user } = useAuthUser();
  const { properties, isLoading } = useAppSelector(selectProperties);
  const [property, setProperty] = React.useState<Property | null>();

  useDeepCompareEffect(() => {
    if (user?.id) {
      dispatch(thunkListProperties(queryParams));
    }
  }, [user?.id, dispatch, queryParams]);

  const propertiesData = properties.data ?? [];

  const handleDelete = async (data: Property) => {
    dispatch(thunkDeleteProperty(String(data.id)))
      .unwrap()
      .then(() => {
        dispatch(thunkListProperties(data.userId));
        setProperty(null);
        showMessage('success', 'Property deleted successfully!');
      })
      .catch(() => showMessage('error', 'Failed to delete property.'));
  };

  const handleUpdate = async (data: Property) => {
    dispatch(
      thunkPatchProperty({
        id: String(data.id),
        data: { isPublished: data.isPublished ? false : true, id: data.id },
      })
    )
      .unwrap()
      .then(() => {
        dispatch(thunkListProperties(data.userId));
        showMessage(
          'success',
          `Property ${data.isPublished ? 'Unpublished' : 'published'} successfully`
        );
      })
      .catch(() => showMessage('error', 'Error updating Property'));
  };

  const { Title } = Typography;
  const getName = (name: any) => (
    <>
      <Avatar.Group>
        <Avatar className='shape-avatar' shape='square' size={60}></Avatar>
        <div className='avatar-info'>
          <Title level={5}>{truncateString(name)}</Title>
        </div>
      </Avatar.Group>{' '}
    </>
  );

  const [search, setSearch] = React.useState(queryParams.title);
  const debouncedSearch = useDebounce(search ?? '', 1000);

  useUpdatedEffect(() => {
    // update with undefined if debouncedSearchTerm is an empty string
    updateQueryParams({ title: debouncedSearch || undefined });
  }, [debouncedSearch]);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <Link to='#'>{getName(text)}</Link>,
    },
    {
      title: 'LISTING TYPE',
      dataIndex: 'listingType',
      key: 'listingType',
    },
    {
      title: 'PROPERTY TYPE',
      dataIndex: 'propertyType',
      key: 'propertyType',
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      render: (price: string) => <p>{formatMoney(price)}</p>,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => (
        <Tooltip title={dayjs(date).format('YYYY-MM-DD')} color='blue' key='blue'>
          <span
            style={{
              cursor: 'pointer',
            }}
          >
            {getDetailedRelativeTime(date)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, data: Property) => {
        return (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'delete',
                  label: 'Delete',
                  icon: <DeleteOutlined />,
                  danger: true,
                  onClick: () => setProperty(data),
                },
                {
                  key: 'edit',
                  label: 'Edit',
                  onClick: () =>
                    navigate('/dashboard/properties/create', { state: { ...data } }),
                  icon: <EditOutlined />,
                },
                {
                  key: `${data.isPublished ? 'unpublished' : 'publish'}`,
                  label: `${data.isPublished ? 'Unpublished' : 'Publish'}`,
                  onClick: () => handleUpdate(data),
                  icon: <PlusCircleOutlined />,
                },
              ],
            }}
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];
  return (
    <>
      <Row className='tabled' gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className='criclebox tablespace mb-24'
            title='Properties'
          >
            <Row gutter={[24, 10]} style={{ padding: '20px' }}>
              <Col xs={24} xl={12}>
                <Input
                  style={{
                    height: '47.3px',
                  }}
                  placeholder='Search for property by title'
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col xs={24} xl={12}>
                <Select
                  style={{ width: '100%' }}
                  placeholder='Search by status'
                  options={[
                    { label: 'Published', value: true },
                    { label: 'Not Published', value: false },
                  ]}
                  onChange={(v) => updateQueryParams({ publishedAt: v })}
                />
              </Col>
            </Row>
            <div className='table-responsive'>
              <Table
                loading={isLoading}
                className='ant-border-space'
                columns={columns}
                dataSource={propertiesData}
                pagination={false}
              />
            </div>
          </Card>

          {propertiesData.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <GenericPagination
                limit={properties.limit}
                skip={properties.skip}
                total={properties.total}
                setQuery={updateQueryParams}
              />
            </div>
          )}
        </Col>
      </Row>

      <Modal
        title='Delete Property'
        open={!!property}
        onOk={() => handleDelete(property!)}
        confirmLoading={isLoading}
        onCancel={() => setProperty(null)}
        okText='Delete'
        okButtonProps={{
          style: { backgroundColor: '#f5222d', borderColor: '#f5222d', color: '#fff' },
        }}
      >
        <p>Are you sure, you want delete property?</p>
      </Modal>
    </>
  );
}
