import { Button, Card, Col, Form, Row, Steps } from 'antd';
import { useState } from 'react';
import BasicDetails from './components/BasicDetails';
import LocationDetails from './components/LocationDetails';
import Media from './components/Media';
import FinancialDetails from './components/FinancialDetails';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { thunkCreateProperty, thunkPatchProperty } from './store/thunks';
import { useLocation, useNavigate } from 'react-router-dom';
import showMessage from 'src/app/core/utils/show-message';
import { selectProperties } from './store/adminPropertySlice';
import { Property } from 'rentfree-api';

export default function ListProperty() {
  const navigate = useNavigate();
  const location = useLocation();
  const property = location.state as Property;

  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [propertyId, setPropertyId] = useState<string>();
  const [formData, setFormData] = useState({
    isServiced: false,
    status: 'available',
  });
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectProperties);

  const onFinish = (values: any) => {
    const { lga, ...rest } = values;
    const [state, lg] = lga?.split('-') ?? [];
    dispatch(
      thunkCreateProperty({
        state: state.trim(),
        lga: lg.trim(),
        city: state.trim(),
        ...rest,
      })
    )
      .unwrap()
      .then((data) => {
        setPropertyId(data.id);
        setCurrent((prev) => prev + 1);
        showMessage('success', 'Property created successfully');
      })
      .catch((error) => {
        showMessage('error', error.message ?? 'unable to create property');
      });
  };

  const UpdateProperty = (data: Property) => {
    dispatch(
      thunkPatchProperty({
        id: String(property.id),
        data: { ...data },
      })
    )
      .unwrap()
      .then(() => {
        setPropertyId(String(property.id));
        showMessage('success', 'Property updated successfully');
      })
      .catch(() => showMessage('error', 'Error updating Property'));
  };

  const next = async () => {
    try {
      const values = await form.validateFields();
      setFormData((prevData) => ({ ...prevData, ...values }));
      setCurrent((prev) => prev + 1);
      form.setFieldsValue({ ...formData, ...values });
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
    form.setFieldsValue(formData);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalData = { ...formData, ...values };
      onFinish(finalData);
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const steps = [
    {
      title: 'Basic Information',
      content: <BasicDetails property={property} />,
    },
    {
      title: 'Financial Details',
      content: <FinancialDetails property={property} />,
    },
    {
      title: 'Location',
      content: <LocationDetails property={property} />,
    },
    {
      title: 'Media',
      content: <Media propertyId={propertyId} />,
    },
  ];

  return (
    <Row>
      <Col offset={3} span={18}>
        <Steps
          current={current}
          items={steps}
          style={{ marginBottom: 40 }}
          progressDot
          responsive
        />
        <Card>
          <Form
            form={form}
            layout='vertical'
            onFinish={property ? UpdateProperty : onFinish}
            initialValues={{
              isFurnished: false,
              hasGarden: false,
              hasWater: false,
              hasElectricity: false,
            }}
          >
            <Row gutter={[16, 16]}>{steps[current].content}</Row>
            <Row style={{ marginTop: 24, justifyContent: 'flex-start', columnGap: 10 }}>
              {current > 0 && current < steps.length - 2 && (
                <Button onClick={() => prev()}>Previous</Button>
              )}
              {current < steps.length - 2 && (
                <Button type='primary' onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 2 && (
                <Button
                  type='primary'
                  onClick={handleSubmit}
                  loading={isLoading === true}
                >
                  Done
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type='primary' onClick={() => navigate('/dashboard/properties')}>
                  Done
                </Button>
              )}
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
