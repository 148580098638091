import * as React from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Typography,
  Dropdown,
  Input,
  Select,
  Switch,
} from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectLoading, selectUsers } from '../store/usersSlice';
import { User, UserQuery } from 'rentfree-api';
import { deleteUserThunk, getUsersThunk, updateUserThunk } from '../thunks/usersThunk';
import dayjs from 'dayjs';
import showMessage from 'src/app/core/utils/show-message';
import useQueryParams from 'src/app/hooks/useQueryParams';
import useDeepCompareEffect from 'src/app/hooks/useDeepCompareEffect';
import useDebounce from 'src/app/hooks/useDebounce';
import useUpdatedEffect from 'src/app/hooks/useUpdatedEffect';
import { useNavigate } from 'react-router-dom';
import { Roles } from 'src/app/routes/guards/RoleProtectedRoute';
import GenericPagination from 'src/app/core/components/GenericPagination';

export default function ViewUsers() {
  const { queryParams, updateQueryParams } = useQueryParams<UserQuery>();
  const users = useAppSelector(selectUsers);
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const usersData = users.data ?? [];

  useDeepCompareEffect(() => {
    dispatch(getUsersThunk(queryParams));
  }, [dispatch, queryParams]);

  const handleDelete = async (data: any) => {
    dispatch(deleteUserThunk(String(data.id)))
      .unwrap()
      .then(() => {
        dispatch(getUsersThunk(queryParams));
        showMessage('success', 'user deleted successfully!');
      })
      .catch(() => showMessage('error', 'Failed to delete user.'));
  };

  const handleUpdate = async (data: User) => {
    dispatch(
      updateUserThunk({
        id: String(data.id),
        data: { isActive: data.isActive ? false : true },
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getUsersThunk(queryParams));
        showMessage(
          'success',
          `User ${data.isActive ? 'disable' : 'enable'} successfully`
        );
      })
      .catch(() => showMessage('error', 'Error updating user'));
  };

  const [search, setSearch] = React.useState(queryParams.q);
  const debouncedSearch = useDebounce(search ?? '', 1000);

  useUpdatedEffect(() => {
    // update with undefined if debouncedSearchTerm is an empty string
    updateQueryParams({ q: debouncedSearch || undefined });
  }, [debouncedSearch]);

  const { Title } = Typography;

  const getName = (user: User) => (
    <>
      <Avatar.Group>
        <Avatar
          className='shape-avatar'
          shape='square'
          size={40}
          src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${user.id}`}
        ></Avatar>
        <div className='avatar-info'>
          <Title level={5}>
            {user.firstName} {user.lastName}
          </Title>
          <p>{user.email}</p>
        </div>
      </Avatar.Group>{' '}
    </>
  );

  const columns = [
    {
      title: 'NAME',
      key: 'name',
      render: (_: any, data: User) => <>{getName(data)}</>,
      width: '32%',
    },
    {
      title: 'ROLE',
      key: 'role',
      render: (_: any, user: User) => (
        <div className='author-info'>
          <Title level={5}>{user.role}</Title>
        </div>
      ),
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (_: any, user: User) => (
        <Switch
          loading={loading === true}
          checked={!user.isActive}
          onChange={() => handleUpdate(user)}
        />
      ),
    },
    {
      title: 'CREATED AT',
      key: 'createdAt',
      render: (_: any, user: User) => dayjs(user.createdAt).format('YYYY-MM-DD'),
    },
    {
      title: '',
      key: 'actions',
      render: (_: any, data: User) => {
        return (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'delete',
                  label: 'Delete',
                  icon: <DeleteOutlined />,
                  danger: true,
                  onClick: () => handleDelete(data),
                },
                {
                  key: 'edit',
                  label: 'Edit',
                  onClick: () => {
                    navigate('/dashboard/users/create', { state: { ...data } });
                  },
                  icon: <EditOutlined />,
                },
              ],
            }}
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const roleOptions = Object.values(Roles).map((role) => ({
    value: role,
    label: role.charAt(0).toUpperCase() + role.slice(1),
  }));

  return (
    <Row className='tabled' gutter={[24, 0]}>
      <Col xs={24} xl={24}>
        <Card bordered={false} className='criclebox tablespace mb-24' title='Users Table'>
          <Row gutter={[24, 0]} style={{ padding: '20px' }}>
            <Col xs={24} xl={12}>
              <Input
                style={{
                  height: '47.3px',
                }}
                placeholder='Search for users by name/email'
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col xs={24} xl={12} style={{ display: 'flex', gap: '10px' }}>
              <Select
                style={{ width: '100%' }}
                placeholder='Search by user type'
                options={roleOptions}
                onChange={(v) => updateQueryParams({ role: v })}
              />
              <Select
                style={{ width: '100%' }}
                placeholder='Search by status'
                options={[
                  { label: 'Active', value: true },
                  { label: 'Inactive', value: false },
                ]}
                onChange={(v) => updateQueryParams({})}
              />
            </Col>
          </Row>

          <div className='table-responsive'>
            <Table
              columns={columns}
              dataSource={usersData}
              pagination={false}
              className='ant-border-space'
              loading={loading === true}
            />
          </div>
        </Card>

        {usersData.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GenericPagination
              limit={users.limit}
              total={users.total}
              skip={users.skip}
              setQuery={updateQueryParams}
            />
          </div>
        )}
      </Col>
    </Row>
  );
}
