import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectLoading } from '../store/usersSlice';
import { User } from 'rentfree-api';
import { createUserThunk, updateUserThunk } from '../thunks/usersThunk';
import showMessage from 'src/app/core/utils/show-message';
import { UserAddOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

export default function CreateUser() {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state as User;

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const createUser = (values: any) => {
    const { addMore, ...user } = values;
    user.password = 'password';

    dispatch(createUserThunk(user))
      .unwrap()
      .then(() => {
        showMessage('success', 'User created successfully');
        if (!addMore) {
          navigate('/dashboard/users/');
          return;
        }
        form.resetFields();
      })
      .catch((error) => {
        showMessage('error', 'Error creating user: ' + error.message);
      });
  };

  const updateUser = (values: any) => {
    dispatch(updateUserThunk({ id: String(user.id), data: { ...values } }))
      .unwrap()
      .then(() => {
        showMessage('success', 'User updated  successfully');
      })
      .catch(() => showMessage('error', 'Error updating user'));
  };

  return (
    <Row>
      <Col lg={{ span: 12, offset: 6 }} sm={{ span: 24 }}>
        <Card title={user ? 'Update User' : 'Create User'}>
          <Form {...layout} onFinish={user ? updateUser : createUser} form={form}>
            <Form.Item
              initialValue={user ? user.firstName : ''}
              label='First Name'
              name='firstName'
              required
            >
              <Input type='text' placeholder='Enter first name' />
            </Form.Item>
            <Form.Item
              initialValue={user ? user.lastName : ''}
              label='Last Name'
              name={'lastName'}
              required
            >
              <Input type='text' placeholder='Enter last name' />
            </Form.Item>
            <Form.Item
              initialValue={user ? user.email : ''}
              label='Email'
              name={'email'}
              rules={[{ required: true, type: 'email' }]}
            >
              <Input type='email' placeholder='Enter email' />
            </Form.Item>
            <Form.Item
              initialValue={user ? user.phone : ''}
              label='Phone Number'
              name={'phone'}
              rules={[{ required: true, type: 'string' }]}
            >
              <Input type='text' placeholder='Enter phone number' />
            </Form.Item>
            <Form.Item
              initialValue={user ? user.role : ''}
              label='User Type'
              required
              name={'role'}
            >
              <Select
                placeholder='Select user type'
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Landlord', value: 'landlord' },
                  { label: 'Agent', value: 'agent' },
                  { label: 'User', value: 'user' },
                ]}
              />
            </Form.Item>
            <Row style={{ justifyContent: 'space-evenly' }}>
              {user ? (
                <Col span={10} offset={2}></Col>
              ) : (
                <Col span={10} offset={2}>
                  <Form.Item
                    required={false}
                    label={null}
                    valuePropName='checked'
                    name={'addMore'}
                  >
                    {/* Option to create more users after subit */}
                    <Checkbox>Create more users</Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col span={10} offset={2}>
                <Button
                  icon={<UserAddOutlined />}
                  className='btn btn-primary'
                  type='primary'
                  htmlType='submit'
                  loading={loading === true}
                  style={{ float: 'right' }}
                >
                  {user ? 'Update User' : 'Create User'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
