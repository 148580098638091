import * as React from 'react';
import { Col, Row, Breadcrumb, Spin, Button, message } from 'antd';
import { ReactComponent as Like } from 'src/app/asset/icons/like.svg';
import { ReactComponent as Share } from 'src/app/asset/icons/share.svg';
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import BookNow from './components/book-now/BookNow';
import ReviewActivities from './components/review-activities/ReviewActivities';
import Sections from './components/sections/sections';
import PropertyFeaturesPage from './components/features/PropertyFeaturesPage';
import Offers from './components/offers/offers';
import './propertyDetails.css';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectGetProperties } from '../property/store/propertySlice';
import { thunkGetProperty } from '../property/store/thunk';
import { APP_URL } from 'src/app/lib/variables';

export default function PropertyDetails() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { property, loading } = useAppSelector(selectGetProperties);

  React.useEffect(() => {
    dispatch(thunkGetProperty(String(id)));
  }, [dispatch, id]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'check out this property',
          text: 'I found something amazing!',
          url: `${APP_URL}/property/${property?.id}`,
        });
      } catch (error) {
        message.error('Failed to share');
      }
    }
  };

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShare();
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '100px',
            marginBottom: '100px',
            justifyContent: 'center',
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <>
          {property && (
            <div className='property-container page-content'>
              <Breadcrumb
                items={[
                  {
                    title: <a href='/'>Home</a>,
                  },
                  {
                    title: <a href='/property'>Property for rent</a>,
                  },
                  {
                    title: '2 bedroom flat at ikeja',
                  },
                ]}
                style={{ marginTop: '44px' }}
              />
              <div className='header-text'>
                <p>{property.title}</p>
                <div className='action'>
                  <Button type='text' className='sub-button' onClick={handleShareClick}>
                    <Share />
                    Share
                  </Button>
                  <Button type='text' className='sub-button' onClick={handleShareClick}>
                    <Like />
                    Like
                  </Button>
                </div>
              </div>

              {property.files && (
                <Row gutter={[16, 32]}>
                  <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                    <img
                      src={
                        property.files[0]
                          ? property.files[0].url
                          : 'https://place-hold.it/500x500'
                      }
                      alt='property details img'
                      className='img-responsive property__image'
                    />
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                    <Row gutter={[0, 16]}>
                      <img
                        src={
                          property.files[1]
                            ? property.files[1].url
                            : 'https://place-hold.it/500x500'
                        }
                        alt='property details img'
                        className='img-responsive property__image2'
                      />

                      <img
                        src={
                          property.files[2]
                            ? property.files[2].url
                            : 'https://place-hold.it/500x500'
                        }
                        alt='property details img'
                        className='img-responsive property__image2'
                      />
                    </Row>
                  </Col>
                </Row>
              )}
              <Row className='details__page'>
                <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                  <div className='property__serviced'>
                    <div>
                      <h4 className='text-primary-grey'>Serviced</h4>
                      <span>
                        {property.bedrooms} bedrooms . {property.bathrooms} bathrooms
                      </span>
                    </div>
                    <div className='badge__wrapper'>
                      <span className='badge'>
                        Verified <CheckOutlined />
                      </span>
                    </div>
                  </div>

                  <Sections>
                    <PropertyFeaturesPage />
                  </Sections>

                  <Sections>
                    <Logo />
                    <p style={{ marginTop: '12px' }}>
                      Every booking includes free protection from Host cancellations,
                      listing inaccuracies, and other issues like trouble checking in.
                    </p>
                    {/* <a>Learn more</a> */}
                  </Sections>
                  <Sections>
                    <p>{property.description}</p>
                  </Sections>
                  <Sections>
                    <Offers />
                  </Sections>
                  <Sections>
                    <ReviewActivities />
                  </Sections>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                  <BookNow />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className='property-search__location'>
                    <h3>Search location</h3>
                    <div>
                      <img
                        src='https://place-hold.it/500x500'
                        alt='property details img'
                        className='img-responsive property__image'
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}
