import { Pagination } from 'antd';

interface Query {
  $skip?: number | undefined;
}

interface Props {
  limit: number;
  skip: number;
  total: number;
  setQuery: (q: Query) => void;
}

export default function GenericPagination(props: Props) {
  const { limit, total, skip, setQuery } = props;

  const handlePageChange = (page: number, pageSize: number) => {
    const skip = (page - 1) * pageSize;
    setQuery({ $skip: skip });
  };

  return (
    <Pagination
      current={Math.floor(skip / limit) + 1}
      total={total}
      defaultPageSize={limit}
      onChange={handlePageChange}
    />
  );
}
